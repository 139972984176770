import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import moment from "moment";
import "moment-timezone";

import Layout from "../components/layout";
import HelmetWrapper from "../components/helmet-wrapper";
import EventDetail from "../components/event-detail";
import { SHOW_HOME } from "../constants";

class EventTemplate extends React.Component {
  render() {
    const event = get(this.props, "data.contentfulEvent");
    const eventTime = event.doors ? event.doors : event.dateTime;
    const endTime = event.endTime
      ? `–${moment(event.endTime).tz("America/Denver").format("h:mma")}`
      : "";
    const doors = event.doors
      ? `\nDoor${event.home ? "" : "s"} at ${moment(event.doors)
          .tz("America/Denver")
          .format("h:mma")}`
      : "";

    let address = event.address;
    if (event.home && typeof window !== "undefined") {
      const showHome = window.localStorage.getItem(SHOW_HOME);
      if (!showHome) {
        address = "Our place. Text us if you need the address.";
      }
    }

    return (
      <Layout location={this.props.location}>
        <div>
          <HelmetWrapper pageTitle={event.title} />
          <div>
            <div className="bg-darkBlue flex flex-col min-h-1/3 justify-end p-4 pt-0">
              <div>
                <div className="md:max-w-5xl md:mx-auto">
                  {event.image && (
                    <img className="mb-4" alt="" src={event.image.file.url} />
                  )}
                  <div
                    className="font-serif text-4xl font-semibold text-theme2-accent md:text-8xl"
                    dangerouslySetInnerHTML={{ __html: event.title }}
                  />
                  <div className="font-extralight font-sans mb-4 leading-6 text-2xl text-lightCool md:text-5xl">
                    {event.description}
                  </div>
                  <div className="flex flex-start text-theme2-accent md:text-2xl">
                    <div className="mr-4 md:mr-6">
                      {moment(eventTime)
                        .tz("America/Denver")
                        .format("MMMM D, h:mma")}
                    </div>
                    <div>{event.location || address}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="event-body m-4 mb-8 font-extralight font-sans text-lg text-darkGray md:max-w-2xl md:mx-auto md:mt-12"
              dangerouslySetInnerHTML={{
                __html: event.body.childMarkdownRemark.html,
              }}
            />
            <div className="bg-lightCool p-4 md:max-w-2xl md:mx-auto">
              <div className="text-theme1-accent text-xs tracking-wider uppercase">
                Details
              </div>
              <EventDetail title="Address" body={address} />
              <EventDetail
                title="Date & Time"
                body={`${moment(event.dateTime)
                  .tz("America/Denver")
                  .format("MMMM D, YYYY, h:mma")}${endTime}${doors}`}
              />
              {event.home && (
                <>
                  <hr className="border-lightestGray" />
                  <EventDetail
                    title="First-time Visitors"
                    body="If this is your first time visiting us, we’re going to ask to take your photo."
                  />
                  <EventDetail
                    title="All Visitors"
                    body="We have a guest book that you gotta sign, every time."
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EventTemplate;

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      address
      body {
        childMarkdownRemark {
          html
        }
      }
      dateTime
      description
      doors
      endTime
      home
      image {
        file {
          url
        }
      }
      location
      title
    }
  }
`;
